.container {
  width: 680px;
  margin: -50px -80px;
}

.tileWrapper {
  margin: 30px 60px;
  display: inline-flex;
}

.tile {
  width: 200px;
  height: 200px;
  font-size: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}