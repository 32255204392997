body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  --primary: #6346e5;
  --secondary: #3b82f6;
}

button {
  font-family: 'Poppins', sans-serif;
}

h1 {
  margin-top: 0;
  font-size: 60px;
  line-height: 1.1;

  @media all and (max-width: 1024px) {
    font-size: 40px;
  }
}

h2 {
  font-size: 30px;
  font-weight: 700;

  @media all and (max-width: 1024px) {
    font-size: 25px;
  }
}

h3 {
  font-weight: 100;
  font-size: 21px;

  @media all and (max-width: 1024px) {
    font-size: 19px;
  }
}

table td {
  padding: 5px 15px;
}

code {
  font-family: 'Fira Code', monospace;
}

pre {
  font-size: 13px;
  line-height: 1.5;
  padding: 15px 25px !important;
  background: #eef2ff;
  border-radius: 10px;
  text-align: left;
}

.App {
  overflow: hidden;
}