.content {
  display: block;
  margin: 0 auto;
  max-width: 820px;
  padding: 0 20px;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  margin-bottom: 100px;

  h2 {
    margin-top: 40px;
    color: var(--primary);
  }
}

.example {
  display: flex;

  @media all and (max-width: 1024px) {
    flex-direction: column;
  }
}

.exampleWrapper {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 20px;
}

.box {
  background: linear-gradient(50deg, var(--primary) 0%, var(--secondary) 100%);
  border: none;
  color: #ffffff;
  text-transform: uppercase;
}

.preview {
  width: 250px;
  padding-left: 50px;
  align-items: center;
  display: flex;
  justify-content: center;

  @media all and (max-width: 1024px) {
    width: auto;
    padding-left: 0;
    margin-bottom: 10px;
  }
}