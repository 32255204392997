.container {
  height: 480px;
  padding: 50px 50px 0;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  position: relative;

  @media all and (max-width: 1024px) {
    background: linear-gradient(50deg, var(--primary) 0%, var(--secondary) 100%);
    height: auto;
    padding: 50px;
  }
}

.text {
  width: 600px;
  max-width: 100%;
  color: #ffffff;
  position: absolute;
  left: calc(50% - 110px);
  transform: translateX(-50%);

  &:before {
    content: "";
    position: absolute;
    background: linear-gradient(50deg, var(--primary) 0%, var(--secondary) 100%);
    width: 150vw;
    height: 600px;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translate(-75vw, -100px) rotate(-10deg);
  }

  a {
    color: #ffffff;
  }

  h1 {
    position: relative;
    z-index: 5;
    text-shadow: 0 10px 10px #424edd;
  }

  @media all and (max-width: 1024px) {
    position: relative;
    transform: none;
    left: 0;

    &:before {
      display: none;
    }
  }
}

.tiles {
  position: absolute;
  top: -100px;
  left: calc(50% + 280px);
  transform: scale(0.5, 0.35) skew(43deg, -36deg) translateY(-234px);
  z-index: 1;

  @media all and (max-width: 1024px) {
    display: none;
  }
}
