.content {
  position: relative;
  z-index: 1;
  max-width: 820px;
  margin: 0 auto;
  padding: 20px;

  h2 {
    color: white;
    margin-bottom: 30px;

    @media all and (max-width: 1024px) {
      color: var(--primary);
    }
  }
}